/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from "@chakra-ui/react";
import {CodeSandbox} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "import { useCallback, useState, useEffect } from \"react\";\nimport \"./styles.css\";\n\nexport default function App() {\n  const [fooData, setFooData] = useState<null | any>(null);\n\n  const fetchData = useCallback(async () => {\n    const res = await fetch(\"https://jsonplaceholder.typicode.com/posts/1\");\n    const data = await res.json();\n    console.log(data);\n    setFooData(data);\n  });\n  useEffect(() => {\n    fetchData();\n  }, []);\n  return (\n    <div className=\"App\">\n      {fooData ? (\n        <div>\n          <div>{fooData.userId}</div>\n          <div>{fooData.title}</div>\n          <div>{fooData.body}</div>\n        </div>\n      ) : (\n        <div></div>\n      )}\n    </div>\n  );\n}\n")), "\n", React.createElement(_components.h2, null, "Living Pattern Validations"), "\n", React.createElement(Box, {
    as: CodeSandbox,
    mb: 6,
    codeSandboxId: "react18-async-data-fetching-patterns-v1-0-h6wd8s"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
